import React, { Component } from 'react';
import {BrowserRouter, Route, HashRouter, Redirect} from 'react-router-dom';
import Login from './components/Login';
import TimeSelection from './components/TimeSelection';
import NewSelection from './components/NewSelection';
import ProductDetail from './components/ProductDetail';
import ProductsDetail from './components/ProductsDetail';
import ContactUs from './components/ContactUs';
import Orderlisting from './components/Orderlisting';
import ForgetPassword from './components/ForgetPassword';
import ChangePassword from './components/ChangePassword';
import ResetPassword from './components/ResetPassword';
import InvoiceList from './components/InvoiceList';
class App extends Component {

	constructor(props){
		super(props);
		this.state={

		}
	}

render() {
return (
  <div>
<HashRouter>

<Route exact path="/" component={Login}/>
<Route path={`${localStorage.getItem('orderId')? '/item-selection/:orderId' : '/item-selection' }`} component={TimeSelection}/>
<Route path={`${localStorage.getItem('orderId')? '/new-selection/:orderId' : '/new-selection' }`} component={NewSelection}/>
<Route path="/invoice-list" component={InvoiceList}/>
<Route path={`${localStorage.getItem('orderId')? '/product-detail/:orderId' : '/product-detail' }`} component={ProductDetail}/>
<Route path="/contact-us" component={ContactUs}/>
<Route path="/products-detail" component={ProductsDetail}/>
<Route path="/order-listing" component={Orderlisting}/>
<Route path="/forget-password" component={ForgetPassword}/>
<Route path="/change-password" component={ChangePassword}/>
<Route path="/reset-password" component={ResetPassword}/>
</HashRouter>

</div>
);
}
}
export default App;
