import React, { Component } from "react";
import $ from "jquery";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { api_url } from "../Config.js";
import { PostData } from "./PostData.js";
import checkbox from "../assets/images/checkbox-icon.png";
import swal from "sweetalert";
class InvoiceListing extends Component {
   constructor(props) {
      super(props);
      this.state = {
         items: JSON.parse(localStorage.getItem("cartProduct")),
         redirectToReferrer: false,
         user_id: JSON.parse(sessionStorage.getItem("userData"))
            ? JSON.parse(sessionStorage.getItem("userData"))
            : JSON.parse(localStorage.getItem("userData")),
            editData: JSON.parse(sessionStorage.getItem("editData"))
            ? JSON.parse(sessionStorage.getItem("editData"))
            :[],
         base_url: api_url.base_url,
         total_amount: localStorage.getItem("total_amount")
            ? localStorage.getItem("total_amount")
            : 0,
         order_no: localStorage.getItem("order_no")
            ? localStorage.getItem("order_no")
            : null,
         email: "",
         redirect: false,
         loading_: false,
         loading__: false,
         loading___: false,
         loading: false,
         orderId: localStorage.getItem("orderId")
            ? localStorage.getItem("orderId")
            : "",
        discount_type: localStorage.getItem("dis_type")
        ? localStorage.getItem("dis_type")
        : 0,
        order_no: localStorage.getItem("order_no")
        ? localStorage.getItem("order_no")
        : 0,
        totalorder: localStorage.getItem("totalorder")
        ? localStorage.getItem("totalorder")
        : 0,
        discount: localStorage.getItem("discount")
        ? localStorage.getItem("discount")
        : 0,
        dis_type: localStorage.getItem("dis_type")
        ? localStorage.getItem("dis_type")
        : 0,
        customer_id: localStorage.getItem("customer_id")
        ? localStorage.getItem("customer_id")
        : 0,
        order_type: localStorage.getItem("order_type")
        ? localStorage.getItem("order_type")
        : 0,
        user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,

         status: "Pending",
         editData: [],
         suffix:'',
         decimalValue:null,
         orderToDetails:[],
         saleMan:[],
         orderType:[],
         orderDetails:[],
      };
   }

   componentDidMount() {
    console.log(this.state.editData)
   const requiredURL = this.state.base_url + "sale_api/getAllAboutTheOrder/"+this.state.customer_id+"/"+this.state.user_id+"/"+this.state.order_type+"/"+this.state.order_no
    fetch(requiredURL)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            orderToDetails: result.customer,
            orderType: result.ordertype,
            saleMan: result.users,
            orderDetails:result.orderDetails
          });
        },
        (error) => {
          this.setState({ error });
        }
      );


    const decimalsUrl = this.state.base_url + "sale_api/getDecimals";
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      const sufixurl = this.state.base_url + "sale_api/GetSuffix";
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

      $(".process_complete").show();
      $(".last_step").hide();
      $(".footer").hide();
      if (localStorage.getItem("orderId") !== null) {
         const getIdUrl =
            this.state.base_url +
            "sale_api/getBySaleOrder/" +
            localStorage.getItem("orderId");
         fetch(getIdUrl)
            .then((res) => res.json())
            .then(
               (result) => {

                  this.setState(
                     {
                        editData: result.basic_details,
                        loading: false,
                        status: result.basic_details.saleOrderStatus,
                     },
                     function () {
                        // localStorage.setItem('total_amount',((result.basic_details.total)).toFixed(0))
                        // console.log(localStorage.getItem('total_amount'))
                     }
                  );
               },
               (error) => {}
            );
      }
   }

   componentWillMount() {
      if (sessionStorage.getItem("userData")) {
         this.getUserData();
      } else if (localStorage.getItem("userData")) {
         this.getUserData();
      } else {
         this.setState({ redirectToReferrer: true });
      }
   }
   getUserData() {
      JSON.parse(sessionStorage.getItem("userData"));
      JSON.parse(localStorage.getItem("userData"));
   }
   save_records() {
      if (this.state.items === null) {
         swal("Please select some products");
      } else {
         this.setState({ loading_: true });
         PostData("save_products", this.state).then((result) => {
            if (result) {
               this.setState({ redirect: true });
               localStorage.removeItem("total_amount");
               localStorage.removeItem("cartProduct");
               localStorage.removeItem("order_no");
               localStorage.removeItem("discount");
               localStorage.removeItem("orderId");
               localStorage.removeItem("dis_type");
            }
         });
      }
   }
   save_records_email() {
      this.setState({ loading___: true });
      let userID = this.state.user_id;
      const apiUrl =
         this.state.base_url + `sale_api/getUserName?userId=${userID}`;
      fetch(apiUrl)
         .then((res) => res.json())
         .then(
            (result) => {
               this.setState({
                  email: result.email,
               });
               if (this.state.email !== "") {
                  PostData("save_products", this.state).then((result) => {
                     if (result) {
                        this.setState({ redirect: true });
                        localStorage.removeItem("total_amount");
                        localStorage.removeItem("cartProduct");
                        localStorage.removeItem("order_no");
                        localStorage.removeItem("discount");
                        localStorage.removeItem("orderId");
                        localStorage.removeItem("dis_type");

                        //  $(".process_complete").hide();

                        // $(".last_step").show();
                        // $(".footer").show();
                     }
                  });
               }
            },
            (error) => {
               this.setState({ error });
            }
         );
   }

   printDocument() {
      this.setState({ loading__: true });
      PostData("save_products", this.state).then((result) => {
         if (result) {
            this.setState({ redirect: true });
            localStorage.removeItem("total_amount");
            localStorage.removeItem("cartProduct");
            localStorage.removeItem("order_no");
            localStorage.removeItem("discount");
            localStorage.removeItem("orderId");
            localStorage.removeItem("dis_type");
            //  $(".process_complete").hide();

            // $(".last_step").show();
            // $(".footer").show();
         }
      });
   }
   onDocumentLoadSuccess(numPages) {
      this.setState({ numPages });
   }

   onApprove(e) {
      let ischecked = e.target.checked;
      if (ischecked) {
         this.setState({ status: "Approved" });
      } else {
         this.setState({ status: "Pending" });
      }
   }

   render() {
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        const mydate = (this.state.orderDetails) ? this.state.orderDetails.created_on : formatedDate;
        let discountValue = 0;
        const discount_type = localStorage.getItem('dis_type');
        const discount_amount = localStorage.getItem('discount');
        if(discount_type === '1')
        {
          discountValue = discount_amount/100;
        }
        else{
          discountValue = discount_amount;
        }

        let sign = '';
        if(discount_type === '1')
        {
          sign = '%';
        }
        else{
          sign = this.state.suffix;
        }
      const { loading, loading_, loading__, loading___ } = this.state;
      const items = JSON.parse(localStorage.getItem("cartProduct"));

      function printPrice(item)
      {
        let pricevalue = 0;
        if(discount_type==='1')
          {
            pricevalue = item.unit_price * item.qty - (item.unit_price * item.qty * discountValue);
          }else{
            pricevalue  = item.unit_price * item.qty - ( item.qty * discountValue);
          }

          return pricevalue;
      }

      if (this.state.redirectToReferrer) {
         return <Redirect to={`/`} />;
      }
      if (this.state.redirect) {
         return <Redirect to={`/order-listing`} />;
      }
      return (
         <div>
            <Header />

            <div className="main_body">
               <div className=" order_lists" id="order_lists_load">
                  <div className="user_login Select_language process_complete">
                     <div className="form_box select_items_pdt">
                        <h3 style={{ marginBottom: "0px" }}>Sale Order <Link className="angle_back" to='/product-detail'><i className="fa fa-angle-left"></i></Link> </h3>
                     </div>
                     <div className="main_row">
                      <div className="col-8- invoice-left-right padd-left- p-padd invoice-space">
                        <ul> 
                            <li><span>Order No</span> <b>:</b>SO-{this.state.order_no}</li>     
                            <li><span>Date</span> <b>:</b>{mydate}</li>
                            <li><span>Order Type</span> <b>:</b>{this.state.orderType.order_type_name}</li>
                            <li><span>Sale Man</span> <b>:</b>{this.state.saleMan.first_name+' '+this.state.saleMan.last_name}</li>
                        </ul>
                      </div>
                      <div className="col-4- invoice-left-right padd-top- p-padd imagge">
                        <ul> 
                            <li><span>Order to</span> <b>:</b>{this.state.orderToDetails.ledgerName}</li>     
                            <li><span>Phone</span> <b>:</b>{this.state.orderToDetails.phone}</li>
                            <li><span>Address</span> <b>:</b>{this.state.orderToDetails.address}</li>
                        </ul>
                      </div>
                     </div>
                     <div className="invoice_table" id="pdfdiv">
                        <table className="table table-bordered table-stripped">
                           <thead>
                              <tr>
                                 <th>Sr#</th>
                                 <th style={{ width: "42%" }}>Product Name</th>
                                 <th>Quantity</th>
                                 <th>Disc.</th>
                                 <th>Total</th>
                              </tr>
                           </thead>
                           <tbody>
                              {items != null ? (
                                 items.map((item, index) => (
                                    <tr key={index}>
                                       <td>{index + parseFloat(1)}</td>
                                       <td>{item.pname}</td>
                                       <td>{item.qty}</td>
                                       <td>{this.state.discount} {sign}</td>
                                       <td>
                                          {
                                            printPrice(item)

                                          }
                                       </td>
                                    </tr>
                                 ))
                              ) : (
                                 <tr>
                                    <td colSpan="9">
                                       There is no data in cart
                                    </td>
                                 </tr>
                              )}
                           </tbody>
                        </table>
                        <div className="total_invoice_bln">
                           <b>
                              Total: <span>{(this.state.total_amount )}</span>
                           </b>
                        </div>
                        <div className="approved">
                           <label>
                              <input
                                 type="checkbox"
                                 checked={this.state.status === "Approved"}
                                 name="status"
                                 value="Approved"
                                 onChange={this.onApprove.bind(this)}
                              ></input>{" "}
                              Approved
                           </label>
                        </div>
                     </div>

                     <div
                        className="total_btm-bar"
                        style={{ padding: "0px", background: "none" }}
                     >
                        <div className="total-amount">
                           <button
                              className="request_door_click"
                              type="button"
                              onClick={this.save_records.bind(this)}
                              disabled={loading_}
                           >
                              {loading_ && (
                                 <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              )}
                              {loading_ && <span>Loading...</span>}
                              {!loading_ && <span>Save</span>}
                           </button>
                           <button
                              className="save-print request_door_click"
                              type="button"
                              onClick={this.printDocument.bind(this)}
                              disabled={loading__}
                           >
                              {loading__ && (
                                 <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              )}
                              {loading__ && <span>Loading...</span>}
                              {!loading__ && <span>Save & Print</span>}
                           </button>
                           <button
                              className="eamil-print request_door_click"
                              type="button"
                              onClick={this.save_records_email.bind(this)}
                              disabled={loading___}
                           >
                              {loading___ && (
                                 <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              )}
                              {loading___ && <span>Loading...</span>}
                              {!loading___ && <span>Save & Email</span>}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="last_step">
               <img src={checkbox} alt="Check Box Icon" />
               <b>Thank You</b>
               <p>We have received your order</p>
            </div>
            <div className="footer">
            </div>
         </div>
      );
   }
}

export default InvoiceListing;
