import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';
import '../assets/css/bootstrap.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import '../assets/css/font-awesome.min.css';
import good  from "../assets/images/logo.png";
import bars  from "../assets/images/bars_icons.png";
import close  from "../assets/images/close-icon.png";
import {NavLink} from 'react-router-dom';
class Header extends Component {


constructor(props) {
super(props);

this.state = {
data:[],
redirectToReferrer: false,
showLogin:sessionStorage.getItem('userData'),
neworder:false,
storage:(sessionStorage.getItem('userData'))? (sessionStorage.getItem('userData')) :(localStorage.getItem('userData'))
};

this.logout = this.logout.bind(this);
this.newOrder = this.newOrder.bind(this);
}
  componentDidMount() {
$(".loader").delay(500).fadeOut("slow");
  $("#overlayer").delay(500).fadeOut("slow");
  $(".loader").delay(500).fadeOut("slow");
  $("#overlayer").delay(500).fadeOut("slow");

// Off Canvas Menu Open & Close
$('#offCanvas').on('click', function () {
    $('.nav-offcanvas').addClass('open');
    $('.offcanvas-overlay').addClass('on');
});
$('#offCanvasClose, .offcanvas-overlay').on('click', function () {
    $('.nav-offcanvas').removeClass('open');
    $('.offcanvas-overlay').removeClass('on');
});
}


logout(){
sessionStorage.setItem("userData",'');
localStorage.setItem("userData",'');
sessionStorage.clear();
localStorage.clear();
this.setState({redirectToReferrer: true},function(){
});
this.setState({showLogin:'yes'},function(){
})

}

validation = () =>{
  localStorage.removeItem("total_amount");
 localStorage.removeItem("cartProduct");
 localStorage.removeItem("order_no");
 localStorage.removeItem("discount");
 localStorage.removeItem("orderId");
 localStorage.removeItem("dis_type");
 // window.location.reload(true)
 return true;
}

newOrder(e){
 if(this.validation())
 {
  // 
  this.setState({neworder:true})
 }else{
  this.setState({neworder:false})
 }
 
}

render() {
  const neworderselection = window.location.hash==="#/new-selection" ? '' : <li><a style={{'color':'white', 'cursor':'pointer'}} onClick={()=>this.newOrder()}>New Order</a></li>
  if (this.state.neworder) {
return (<Redirect to={'/new-selection'}/>)
    
}
if (this.state.redirectToReferrer) {
return (<Redirect to={'/'}/>)
}
              let productDetail = '';
              if(localStorage.getItem('orderId'))
              {
                  productDetail=  <li><NavLink to={`/products-detail/${localStorage.getItem('orderId')}`}>Products List</NavLink></li>

              }else{

                  productDetail = <li><NavLink to="/products-detail">Products List</NavLink></li>
              }

return (
<div>
<a id="offCanvas" className="hamburger"><img  className="ti-menu" src={bars} /></a>
<div className="nav-offcanvas">
    <button type="button" className="close" id="offCanvasClose" aria-label="Close">
        <img className="ti-close" src={close} />
    </button>
    <div className="nav-offcanvas-menu">
        <ul>
            {neworderselection}
            <li><NavLink to="/order-listing">Orders</NavLink></li>

            {
              productDetail
            }
            
            {(this.state.storage)? <li><NavLink onClick={this.logout} to="/" >Logout</NavLink></li> : 
              <li><NavLink to="/">Login</NavLink></li>}

            
        </ul>
    </div>
</div>
<div className="offcanvas-overlay"></div>
<span className="loader">
  <img src={good} alt="" />
</span>

</div>


);

}
}

export default Header;