import React, { Component } from 'react';
import Header from "./Header.js";
import Footer from "./Footer.js";
import {NavLink} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {PostData} from './PostData.js';
import swal from 'sweetalert';
import {api_url} from '../Config.js';
class ChangePassword extends Component {
	constructor(props){
super(props);
this.state = {
email: '',
password: '',
redirectToReferrer: false,
emailError:'',
passError:'',
validError:'',
keeplogin:false,
pass_error:'',
new_password:null,
confirm_password:'',
confirm_code:this.props.location.state.code,
base_url:api_url.base_url,
img_url:api_url.img_url,
};
this.changePassword = this.changePassword.bind(this)
}
componentDidMount(){
  console.log(this.state.confirm_code)
}
changePassword(){
 if(this.state.new_password==null)
 {
this.setState({pass_error:'Please enter your new password'})
 }else{
   if(this.state.new_password === this.state.confirm_password)
  {
    this.setState({ loading: true });
   PostData('reset_password',this.state).then((result) => {
let responseJson = result;
if(responseJson.success){

this.setState({redirectToReferrer: true,loading:false});
}
else
swal(result.error);
});
  }else{
    this.setState({pass_error:'Confirm Password not matched'})
  }
 }
}
componentDidMount(){

    const apiUrl = this.state.base_url+`sale_api/getCompanyLogo`;
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            company_logo: result
          });
          
        },
        (error) => {
          this.setState({ error });
        }
      )

  }

render() {
  const { loading } = this.state;
if (this.state.redirectToReferrer) {
return (
 
  <Redirect to={`/login/1`}/>
  )
}
		return (
<div>
<Header />	

<div className="main_body">
  <div className="login_screen" id="uder_eng_language">
    <div className="logo login_page">
      <img src={this.state.img_url+this.state.company_logo} alt="logo"/>
    </div>
    
    <div className="user_login">
      <div className="form_box">
        <input type="password" placeholder="New Password" name="new_password" onChange={(event)=>this.setState({new_password:event.target.value})} />
       <svg  width="20" height="20" viewBox="0 0 24 24"><path d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm3 17c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm2-6c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z"/></svg>
      </div>
       <div className="form_box">
        <input type="password" placeholder="Confirm Password" name="confirm_password" onChange={(event)=>this.setState({confirm_password:event.target.value})} />
        <svg  width="20" height="20" viewBox="0 0 24 24"><path d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm3 17c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm2-6c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z"/></svg>
       <p className="error_box">{this.state.pass_error}</p>
      </div>
      
      <div className="form_box" id="login_items">
       
      
        

        <button className="button-loadings" style={{'fontSize':'18px', width:'100%'}} type="button" onClick={this.changePassword} disabled={loading}>
          {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && <span>Loading...</span>}
          {!loading && <span>Reset Password</span>}
        </button>

      </div>

      </div>
   
 
  </div>
</div>
</div>
		);
	}
}
export default ChangePassword;