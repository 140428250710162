import React, { Component } from 'react';
import logoimg  from "../assets/images/logo.png";
import Header from "./Header.js";
import Footer from "./Footer.js";
import {NavLink} from 'react-router-dom';
import {PostData} from './PostData';
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
class ContactUs extends Component {
    constructor(props){
super(props);
this.state = {

base_url:api_url.base_url,
img_url:api_url.img_url,
};

}

componentDidMount(){

    const apiUrl = this.state.base_url+`sale_api/getCompanyLogo`;
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            company_logo: result
          });
          
        },
        (error) => {
          this.setState({ error });
        }
      )

  }

render() {

		return (
<div>
<Header />	

<div className="main_body contact_page">
  <div className="login_screen" id="uder_eng_language">
    <div className="logo login_page">
      <img src={this.state.img_url+this.state.company_logo} alt="logo"/>
    </div>
    
      <div className="user_login Select_language ">
        
      <div className="form_box select_items_pdt">
        <h3>Contact Us</h3>
      </div>
      <div className="user_login Select_language contact_info">
      <div className="form_box select_items_pdt">
        <img src={logoimg} alt="" />
        <h4>GET IN TOUCH</h4>
        <h3>Visit one of our locations or contact us today</h3>
      </div>
      <div className="form_row">
        <label>Head Office</label>
        <ul>
          <li><i aria-hidden="true" className="fa fa-map-marker"></i>Ayan Plaza, First Floor, Janoobi Bazar, Jauharabad., Jauharabad </li>
          <li><i aria-hidden="true" className="fa fa-envelope"></i> <a href="mailto:www.itivsion.com.pk">www.itivsion.com.pk</a></li>
          <li><i aria-hidden="true" className="fa fa-phone"></i>  <a href="tel:+03324510131" target="_blank">03324510131</a></li>
          <li><i aria-hidden="true" className="fa fa-clock-o"></i> Monday to Saturday: 9:00 AM to 6:30 PM</li>
        </ul>
      </div>
      
    </div>
      
    </div>
  </div>
</div>
</div>
		);
	}
}
export default ContactUs;