import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';
import '../assets/css/bootstrap.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import order  from "../assets/images/order.png";
import contact  from "../assets/images/contact.png";
import whatsapp  from "../assets/images/whatsapp.png";
import share  from "../assets/images/Share.png";
import {NavLink} from 'react-router-dom';
class Footer extends Component {

  constructor(props){
    super(props);
  }

  


render() {


return (
<div>
		<div className="total_btm-bar">
    	<div className="total_amount_box">
      	<b>Total:</b> <span>20,00000</span>
       	<button>Next <i className="fa fa-long-arrow-right"></i></button>
    	</div>   
    </div>



</div>


);

}
}

export default Footer;