import React, { Component } from 'react';
import Header from "./Header.js";
import Footer from "./Footer.js";
import {NavLink} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {PostData} from './PostData.js';
import {api_url} from '../Config.js';
class ChangePassword extends Component {
	constructor(props){
super(props);
this.state = {
email: '',
password: '',
redirectToReferrer: false,
emailError:'',
passError:'',
validError:'',
keeplogin:false,
verify_code:null,
verify_result:null,
base_url:api_url.base_url,
img_url:api_url.img_url,
loading:false,
};
this.checkCode = this.checkCode.bind(this)
}

checkCode(e){
  if(this.state.verify_code==null)
{
this.setState({verify_result:'Please enter 4 digit code'})
}else{
  this.setState({ loading: true });
  let code =  this.state.verify_code
const apiUrl = this.state.base_url+'sale_api/verify_code';
    fetch(apiUrl+'?code='+code)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            verify_result: result,
            loading:false
          });
          
         if(this.state.verify_result === code)
  {
    this.setState({redirectToReferrer:true})
  }else{
    this.setState({redirectToReferrer:false})
  }
        },
        (error) => {
          this.setState({ error });
        }
      )
}


   
}
componentDidMount(){

    const apiUrl = this.state.base_url+`sale_api/getCompanyLogo`;
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            company_logo: result
          });
          
        },
        (error) => {
          this.setState({ error });
        }
      )

  }


render() {
  const { loading } = this.state;
if (this.state.redirectToReferrer) {
return (
   <Redirect to={{
            pathname: '/reset-password',
            state: { code: this.state.verify_result }
        }}
/>
  )
}
		return (
<div>
<Header />	

<div className="main_body">
  <div className="login_screen" id="uder_eng_language">
    <div className="logo login_page">
      <img src={this.state.img_url+this.state.company_logo} alt="logo"/>
    </div>
    
    <div className="user_login">
      <div className="form_box">
        <input type="text" placeholder="4 Digit Code Here" name="verify_code" onChange={(event)=>this.setState({verify_code:event.target.value})} />
       <p className="error_box">{this.state.verify_result}</p>
      </div>
      
      <div className="form_box" id="login_items">
       
      
       
        <button className="button-loadings" style={{'fontSize':'18px', width:'100%'}} type="button" onClick={this.checkCode} disabled={loading}>
          {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && <span>Loading...</span>}
          {!loading && <span>Submit</span>}
        </button>

      </div>

      </div>
   
  
  </div>
</div>
</div>
		);
	}
}
export default ChangePassword;